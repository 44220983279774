import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { apiPost } from '~core'
import { takeEvery } from 'redux-saga/effects'

export const CREATE_NOTIFICATIONS = 'CREATE_NOTIFICATIONS'
export const CREATE_NOTIFICATIONS_ALL = 'CREATE_NOTIFICATIONS_ALL'

export const NotificationRouteName = {
  createNotifications: '/create-notifications',
  createNotificationsAll: '/create-notifications-all',
  route: '/notification',
}

interface CreateNotificationsParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    message: {
      data?: { lessonId?: string; postId?: string; screenPath?: string }
      notification: {
        body: string
        title: string
      }
    }
    targetUsers: string[]
  }
}

function* createNotifications({ onFailure, onSuccess, payload }: CreateNotificationsParams) {
  try {
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${NotificationRouteName.route}${NotificationRouteName.createNotifications}`,
    })
    onSuccess?.(response?.data?.result)
  } catch (e: any) {
    onFailure?.(e)
  }
}

interface CreateNotificationsAllParams extends PayloadAction<any> {
  onFailure?: (params?: any) => void
  onSuccess?: (params?: any) => void
  payload: {
    message: {
      data?: { lessonId?: string; postId?: string; screenPath?: string }
      notification: {
        body: string
        title: string
      }
    }
  }
}

function* createNotificationsAll({ onFailure, onSuccess, payload }: CreateNotificationsAllParams) {
  try {
    const response: AxiosResponse = yield apiPost({
      params: payload,
      url: `${NotificationRouteName.route}${NotificationRouteName.createNotificationsAll}`,
    })
    onSuccess?.(response?.data)
  } catch (e: any) {
    onFailure?.(e)
  }
}

export function* notificationAdminSaga() {
  yield takeEvery(CREATE_NOTIFICATIONS, createNotifications)
  yield takeEvery(CREATE_NOTIFICATIONS_ALL, createNotificationsAll)
}
