import '~assets/style/Post.css'

import { UserProfile, UserRole } from '@teen-care/core'
import { getAssetsUrl, useAppSelector } from '~core'

import { Button } from 'react-bootstrap'
import { UserStatus } from '~saga'
import { sortBy } from 'lodash'
import { userRankingState } from '~redux'

export function UserItem({
  adminIds,
  item,
  setAdminIds,
  setUserIds,
  userIds,
}: {
  adminIds: string[]
  index: number
  item: UserProfile
  setAdminIds: React.Dispatch<React.SetStateAction<string[]>>
  setUserIds: React.Dispatch<React.SetStateAction<string[]>>
  userIds: string[]
}) {
  const { userRankings = [] } = useAppSelector(userRankingState) || {}
  const userRoles: UserRole[] = []
  const { name, phone, totalScore = 0, userBadge, userRoleId, userStatus, username } = item || {}
  const { rankName = '' } =
    sortBy(userRankings, (e) => e.score)
      ?.reverse()
      .find((e) => e.score <= totalScore) || {}

  const { userRoleName = '' } =
    userRoles.find((e) => e?._id?.toString() === userRoleId?.toString()) || {}

  const isAdmin = adminIds?.findIndex((e) => e === item?._id) !== -1
  const isUser = userIds?.findIndex((e) => e === item?._id) !== -1

  function addAdmin() {
    setAdminIds((current) => [item?._id, ...current])
  }
  function removeAdmin() {
    setAdminIds((current) => current.filter((e) => e !== item?._id))
  }

  function addUser() {
    setUserIds((current) => [item?._id, ...current])
  }
  function removeUser() {
    setUserIds((current) => current.filter((e) => e !== item?._id))
  }

  return (
    <tr>
      <td className='align-middle'>
        <span className='fw-normal'>{username}</span>
      </td>
      <td className='align-middle'>
        <span className='post-content fw-normal'>{name}</span>
      </td>
      <td className='align-middle'>
        <span className='post-content fw-normal'>{phone}</span>
      </td>
      <td className='align-middle'>
        <span className='post-content fw-normal'>{userRoleName}</span>
      </td>
      <td className='align-middle'>
        <div
          style={{
            alignItems: 'center',
            backgroundColor: userBadge?.userBadgeColor,
            borderRadius: 20,
            flexDirection: 'row',
            padding: 5,
          }}
        >
          <img
            alt={userBadge?.userBadgeIcon}
            src={getAssetsUrl(userBadge?.userBadgeIcon)}
            style={{ height: 10, marginRight: 5, width: 10 }}
          />
          <span className='fw-normal' style={{ color: userBadge?.userBadgeNameColor }}>
            {userBadge?.userBadgeName || ''}
          </span>
        </div>
      </td>
      <td className='align-middle'>
        <span className='post-content fw-normal'>{totalScore}</span>
      </td>
      <td className='align-middle'>
        <span className='post-content fw-normal'>{rankName}</span>
      </td>
      <td className='align-middle'>
        <span
          className={`post-content fw-normal ${UserStatus.ACTIVE ? 'success' : 'outline-danger'}`}
        >
          {userStatus === UserStatus.ACTIVE ? 'Hoạt động' : 'Khóa'}
        </span>
      </td>
      <td className='align-middle'>
        <Button
          className='m-1'
          variant={isAdmin ? 'outline-danger' : 'outline-primary'}
          onClick={isAdmin ? removeAdmin : addAdmin}
        >
          {isAdmin ? 'Xóa Admin' : 'Thêm Admin'}
        </Button>
        <Button
          className='m-1'
          variant={isUser ? 'outline-danger' : 'outline-primary'}
          onClick={isUser ? removeUser : addUser}
        >
          {isUser ? 'Xóa người dùng' : 'Thêm người dùng'}
        </Button>
      </td>
    </tr>
  )
}
