import '~assets/style/Post.css'

import { forumGroupState, forumTopicState, postHistoryState, postState } from '~redux'

import { Button } from 'react-bootstrap'
import { LIMIT_POST_ITEM } from '~saga'
import { Post } from '@teen-care/core'
import dayjs from 'dayjs'
import { getUserProfile } from '~utils'
import { useAppSelector } from '~core'
import { useNavigate } from 'react-router-dom'

export function PostItem({ index, item }: { index: number; item: Post }) {
  const { currentPage } = useAppSelector(postState) || {}
  const postHistoryRedux = useAppSelector(postHistoryState) || {}
  const { forumGroups } = useAppSelector(forumGroupState) || {}
  const { forumTopics } = useAppSelector(forumTopicState) || {}
  const navigate = useNavigate()
  const { content, creatorId, forumGroupId, forumTopicIds, postStatus, profiles } = item || {}
  const creator = getUserProfile({ profiles, userId: creatorId })

  const postHistory = postHistoryRedux?.postHistory?.[item?._id] || []

  const createdAt = [...postHistory].reverse().find((e) => e.postStatus === 'PENDING')?.createdAt
  const requestReviewAt = [...postHistory].find((e) => e.postStatus === 'PENDING')?.createdAt
  const approvedAt = [...postHistory].reverse().find((e) => e.postStatus === 'APPROVE')?.createdAt
  const group = forumGroups?.find((e) => e._id === forumGroupId)
  const topic = forumTopics?.filter(
    (e) => forumTopicIds?.findIndex((topic) => e?._id === topic) !== -1,
  )

  function getPostStatus() {
    switch (postStatus) {
      case 'APPROVE':
        return <div className='rounded text-center bg-success text-white'>Đã duyệt</div>
      case 'PENDING':
        return <div className='rounded text-center bg-warning text-white'>Chờ duyệt</div>
      case 'REJECT':
        return <div className='rounded text-center bg-danger text-white'>Từ chối</div>
      case 'DELETE':
        return <div className='rounded text-center bg-secondary text-white'>Đã xóa</div>
      default:
        return <div className='rounded text-center bg-warning text-white'>Chờ duyệt</div>
    }
  }

  function gotoPost() {
    navigate(`/forum/post/${item._id}`)
  }

  return (
    <tr onClick={gotoPost}>
      <td className='align-middle'>
        <span className='fw-normal'>{(currentPage - 1) * LIMIT_POST_ITEM + index + 1}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>{group?.groupName}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>{creator?.name}</span>
      </td>
      <td className='align-middle'>
        <span className='post-content fw-normal'></span>
      </td>
      <td className='align-middle w-25'>
        <span className='post-content fw-normal'>{content}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>
          {topic?.length > 0
            ? topic?.map((e, index) =>
                index === topic?.length - 1 ? (
                  e.title
                ) : (
                  <>
                    {e.title}
                    <br />
                  </>
                ),
              )
            : 'Chưa có'}
        </span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>{getPostStatus()}</span>
      </td>
      <td className='align-middle'>
        <span className='fw-normal'>
          <div className='text-dark'>
            Tạo: {createdAt ? dayjs(createdAt).format('HH:mm DD/MM/YYYY') : '...'}
          </div>
          <div className='text-dark'>
            Y/c duyệt: {requestReviewAt ? dayjs(requestReviewAt).format('HH:mm DD/MM/YYYY') : '...'}
          </div>
          <div className='text-dark'>
            Phê duyệt: {approvedAt ? dayjs(approvedAt).format('HH:mm DD/MM/YYYY') : '...'}
          </div>
        </span>
      </td>
      <td className='align-middle'>
        <Button variant='outline-dark'>Thao tác</Button>
      </td>
    </tr>
  )
}
