import { ForumGroup, ForumTopic, PostDetail, Posts, UserBadge, UserRanking } from './forum'
import { Login, NotFound, ServerError } from './auth'
import { ReactNode, useEffect } from 'react'
import { User, UserRole } from './user'
import { createBrowserRouter, useNavigate } from 'react-router-dom'

import { CreateNotification } from './user/CreateNotification'
import { SidebarMenu } from '~components'
import { UserScoreHistory } from './user/UserScoreHistory'
import { useAppSelector } from '~core'
import { userProfileState } from '~redux'

function RouterWithSideBar({ children }: { children?: ReactNode }) {
  const { accessToken } = useAppSelector(userProfileState)
  const navigate = useNavigate()

  useEffect(() => {
    if (!accessToken) navigate('/login')
  }, [navigate, accessToken])

  return (
    <div className='d-flex container-fluid p-0'>
      <SidebarMenu />
      <div className='container-fluid' style={{ backgroundColor: '#EFF0F2' }}>
        {children}
      </div>
    </div>
  )
}

export const router = createBrowserRouter([
  {
    Component: () => (
      <RouterWithSideBar>
        <User />
      </RouterWithSideBar>
    ),
    id: 'root',
    path: '/',
  },
  {
    Component: () => (
      <RouterWithSideBar>
        <User />
      </RouterWithSideBar>
    ),
    id: 'users',
    path: '/users',
  },
  {
    Component: () => (
      <RouterWithSideBar>
        <UserRole />
      </RouterWithSideBar>
    ),
    id: 'users/role',
    path: '/users/role',
  },
  {
    Component: () => (
      <RouterWithSideBar>
        <UserScoreHistory />
      </RouterWithSideBar>
    ),
    id: '/user/score-history',
    path: '/user/score-history/:_id',
  },
  {
    Component: () => (
      <RouterWithSideBar>
        <CreateNotification />
      </RouterWithSideBar>
    ),
    id: 'users/notification',
    path: '/users/notifications',
  },
  {
    Component: () => (
      <RouterWithSideBar>
        <Posts />
      </RouterWithSideBar>
    ),
    id: 'forum/posts',
    path: '/forum/posts',
  },
  {
    Component: () => (
      <RouterWithSideBar>
        <PostDetail />
      </RouterWithSideBar>
    ),
    id: 'forum/post',
    path: '/forum/post/:_id',
  },
  {
    Component: () => (
      <RouterWithSideBar>
        <UserBadge />
      </RouterWithSideBar>
    ),
    id: 'forum/badge',
    path: '/forum/badge',
  },
  {
    Component: () => (
      <RouterWithSideBar>
        <ForumTopic />
      </RouterWithSideBar>
    ),
    id: 'forum/topic',
    path: '/forum/topic',
  },
  {
    Component: () => (
      <RouterWithSideBar>
        <ForumGroup />
      </RouterWithSideBar>
    ),
    id: 'forum/group',
    path: '/forum/group',
  },
  {
    Component: () => (
      <RouterWithSideBar>
        <UserRanking />
      </RouterWithSideBar>
    ),
    id: 'user/ranking',
    path: '/user/ranking',
  },
  {
    Component: Login,
    id: 'login',
    path: '/login',
  },
  {
    Component: NotFound,
    id: 'not-found',
    path: '/404',
  },
  {
    Component: ServerError,
    id: 'server-error',
    path: '/500',
  },
  {
    Component: NotFound,
    id: 'redirect',
    path: '*',
  },
])
