import '~assets/style/Post.css'

import { Badge, Button, Dropdown, Modal } from 'react-bootstrap'
import { LIMIT_USER_ITEM, SET_USER_STATUS, UserStatus } from '~saga'
import { getAssetsUrl, useAppDispatch, useAppSelector } from '~core'
import { userRankingState, userRoleState, userState } from '~redux'

import { UpdateUserBadge } from './UpdateUserBadge'
import { UserProfile } from '@teen-care/core'
import { sortBy } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

export function UserItem({
  editItem = () => {},
  index,
  item,
}: {
  editItem?: (item: UserProfile) => void
  index: number
  item: UserProfile
}) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { currentPage } = useAppSelector(userState) || {}
  const { userRankings = [] } = useAppSelector(userRankingState) || {}
  const { userRoles } = useAppSelector(userRoleState) || {}
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const {
    _id,
    name,
    phone,
    totalScore = 0,
    userBadge,
    userRoleId,
    userStatus,
    username,
  } = item || {}
  const { rankName = '' } =
    sortBy(userRankings, (e) => e.score)
      ?.reverse()
      .find((e) => e.score <= totalScore) || {}

  const { userRoleName = '' } =
    userRoles.find((e) => e?._id?.toString() === userRoleId?.toString()) || {}

  function setUserStatus(_id: string) {
    setShow(false)
    dispatch({
      payload: {
        _id,
        userStatus: userStatus === UserStatus.ACTIVE ? UserStatus.INACTIVE : UserStatus.ACTIVE,
      },
      type: SET_USER_STATUS,
    })
  }

  function gotoUserScoreHistory() {
    navigate(`/user/score-history/${item._id}`)
  }

  return (
    <>
      <tr>
        <td className='align-middle'>
          <span className='fw-normal'>{(currentPage - 1) * LIMIT_USER_ITEM + index + 1}</span>
        </td>
        <td className='align-middle'>
          <span className='fw-normal'>{username}</span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{name}</span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{phone}</span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{userRoleName}</span>
        </td>
        <td className='align-middle'>
          <div
            style={{
              alignItems: 'center',
              backgroundColor: userBadge?.userBadgeColor,
              borderRadius: 20,
              flexDirection: 'row',
              padding: 5,
            }}
          >
            <img
              alt={userBadge?.userBadgeIcon}
              src={getAssetsUrl(userBadge?.userBadgeIcon)}
              style={{ height: 10, marginRight: 5, width: 10 }}
            />
            <span className='fw-normal' style={{ color: userBadge?.userBadgeNameColor }}>
              {userBadge?.userBadgeName || ''}
            </span>
          </div>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{totalScore}</span>
        </td>
        <td className='align-middle'>
          <span className='post-content fw-normal'>{rankName}</span>
        </td>
        <td className='align-middle'>
          <Badge bg={userStatus === UserStatus.ACTIVE ? 'success' : 'danger'}>
            {userStatus === UserStatus.ACTIVE ? 'Hoạt động' : 'Khóa'}
          </Badge>
        </td>
        <td className='align-middle'>
          <Dropdown>
            <Dropdown.Toggle variant='outline-dark' />
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => editItem(item)}>{'Sửa thông tin user'}</Dropdown.Item>
              <Dropdown.Item onClick={handleShow}>
                {userStatus === UserStatus.ACTIVE ? 'Khóa tài khoản' : 'Mở khóa tài khoản'}
              </Dropdown.Item>
              <Dropdown.Item>
                <UpdateUserBadge
                  textOnly
                  content='Gắn huy hiệu cho người dùng'
                  item={item}
                  title='Gắn huy hiệu'
                />
              </Dropdown.Item>
              <Dropdown.Item onClick={gotoUserScoreHistory}>Lịch sử tích điểm</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Xác nhận khóa tài khoản</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userStatus === UserStatus.ACTIVE ? (
            <span>
              Người dùng bị khoá tài khoản sẽ không thể đăng nhập vào hệ thống trên máy tính và điện
              thoại. Bạn có chắc chắn muốn khoá tài khoản người dùng{' '}
              <span className='fw-bold'>{`[${username}] ${name}`}</span> ?
            </span>
          ) : (
            <span>
              Người dùng có thể đăng nhập vào hệ thống trên máy tính và điện thoại sau khi được mở
              tài khoản. Bạn có chắc chắn muốn mở khoá tài khoản người dùng{' '}
              <span className='fw-bold'>{`[${username}] ${name}`}</span> ?
            </span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Hủy
          </Button>
          <Button
            variant={userStatus === UserStatus.ACTIVE ? 'danger' : 'primary'}
            onClick={() => setUserStatus(_id)}
          >
            {userStatus === UserStatus.ACTIVE ? 'Khóa tài khoản' : 'Mở khóa tài khoản'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
