import { Avatar, Slider } from '~components'
import { Button, Form } from 'react-bootstrap'
import {
  CommentInputBar,
  CommentItem,
  PollPost,
  PostHistoryComponent,
  ReactionPost,
} from './components'
import { ForumTopic, Post } from '@teen-care/core'
import { GET_COMMENTS, GET_POST, GET_POST_HISTORY, MANAGE_POST } from '~saga'
import { commentState, forumGroupState, forumTopicState, postHistoryState, postState } from '~redux'
import { formatTimeDifference, getUserProfile, toastServices } from '~utils'
import { useAppDispatch, useAppSelector } from '~core'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MdArrowRight } from 'react-icons/md'
import { faClose } from '@fortawesome/free-solid-svg-icons'

export function PostDetail() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const postId = params?._id || ''
  const { posts } = useAppSelector(postState) || {}
  const { forumGroups } = useAppSelector(forumGroupState) || {}
  const { forumTopics } = useAppSelector(forumTopicState) || {}
  const commentsRedux = useAppSelector(commentState) || {}
  const postHistoryRedux = useAppSelector(postHistoryState) || {}
  const post: Post = (posts?.find((e) => e._id === postId) || {}) as Post
  const {
    content,
    createdAt,
    creatorId,
    forumGroupId,
    isHighLight,
    isPrivate,
    media,
    postStatus,
    profiles,
  } = post || {}

  const creator = getUserProfile({ profiles, userId: creatorId })
  const comments = commentsRedux?.comments?.[post?._id] || []
  const postHistory = postHistoryRedux?.postHistory?.[post?._id] || []

  const group = forumGroups?.find((e) => e._id === forumGroupId)
  const topic = forumTopics?.filter(
    (e) => post?.forumTopicIds?.findIndex((topic) => e?._id === topic) !== -1,
  )
  const selectedTopicIds =
    forumTopics
      ?.filter(
        (forumGroup) =>
          post?.forumTopicIds?.length &&
          post?.forumTopicIds?.findIndex((e) => e?.toString() === forumGroup?._id?.toString()) !==
            -1,
      )
      ?.map((e) => e._id) || []
  const [forumTopicIds, setForumTopicIds] = useState<string[]>(selectedTopicIds || [])
  const [highLight, setHighLight] = useState<boolean>(isHighLight || false)

  useEffect(() => {
    fetchData()
  }, [postId])

  function fetchData() {
    if (postId && postId.length) {
      getPost()
      getComments()
      getPostHistory()
    }
  }

  function getPost() {
    dispatch({
      onFailure: () => navigate(-1),
      payload: { _id: postId },
      type: GET_POST,
    })
  }

  function getComments() {
    dispatch({
      payload: { postId },
      type: GET_COMMENTS,
    })
  }

  function getPostHistory() {
    dispatch({
      payload: { postId },
      type: GET_POST_HISTORY,
    })
  }

  function managePost(postStatus: string) {
    if (postStatus === 'REJECT') {
      toastServices.prompt({
        content: 'Nhập lý do từ chối',
        onConfirm: (reasonReject) => {
          dispatch({
            payload: { _id: postId, forumTopicIds, postStatus, reasonReject },
            type: MANAGE_POST,
          })
        },
        showPrompt: true,
      })
    } else
      dispatch({
        payload: { _id: postId, forumTopicIds, isHighLight: highLight, postStatus },
        type: MANAGE_POST,
      })
  }

  return (
    <div className='container-fluid'>
      <div className='border-bottom p-3 d-flex align-items-center justify-content-between'>
        <div className=' fw-bold'>Chi tiết bài viết</div>
        <FontAwesomeIcon icon={faClose} onClick={() => navigate(-1)} />
      </div>
      <div className='d-flex'>
        <div className='p-3 border-right' style={{ width: '60%' }}>
          <div className='row d-flex align-items-center'>
            <Avatar avatarUrl={creator.avatar} />
            <div className='col'>
              <div className='fw-bold'>
                {creator?.name} {isPrivate ? '(Ẩn danh)' : ''}
                {group?.groupName?.length ? <MdArrowRight /> : null}
                {group?.groupName}
                <MdArrowRight />
                {isHighLight ? 'Bảng tin' : 'Thảo luận'}
              </div>
              <div>{formatTimeDifference(createdAt)}</div>
            </div>
          </div>
          <div className='mt-3 mb-3'>{content}</div>
          <PollPost {...post} />
          <Slider image={media} />

          <div className='mt-3 mb-1 text-primary'>
            {topic?.length > 0 ? topic?.map((e) => `${e.hashTag} `) : ''}
          </div>

          <ReactionPost item={post} />
          {comments.map((item, index) => {
            return <CommentItem {...item} key={index} profiles={profiles} />
          })}
          <CommentInputBar postId={postId} />
        </div>
        <div className='p-3 border border-top-0 border-bottom-0' style={{ width: '40%' }}>
          <div className='d-flex align-items-center pb-3'>
            <div className='w-50 fw-bold'>Trạng thái: </div>
            {postStatus === 'APPROVE' && (
              <div className='m-1 w-50 rounded text-center bg-success text-white p-1'>Đã duyệt</div>
            )}
            {postStatus === 'PENDING' && (
              <div className='m-1 w-50 rounded text-center bg-warning text-white p-1'>
                Chờ duyệt
              </div>
            )}
            {postStatus === 'REJECT' && (
              <div className='m-1 w-50 rounded text-center bg-danger text-white p-1'>Từ chối</div>
            )}
            {postStatus === 'DELETE' && (
              <div className='m-1 w-50 rounded text-center bg-secondary text-white p-1'>Đã xóa</div>
            )}
          </div>
          {postStatus === 'PENDING' || postStatus === 'REJECT' ? (
            <>
              <div className='mb-1'>{'Danh sách topic'}</div>
              {(forumTopics || []).map((item: ForumTopic) => {
                const isChecked =
                  forumTopicIds?.findIndex((e) => e?.toString() === item?._id?.toString()) !== -1
                return (
                  <Form.Check
                    checked={isChecked}
                    className='mb-1'
                    key={item?._id}
                    label={`${item?.title} ${item?.hashTag}`}
                    onChange={(e: any) => {
                      setForumTopicIds((current) =>
                        e?.target?.checked
                          ? [item?._id, ...current]
                          : current?.filter((e) => e !== item?._id),
                      )
                    }}
                  />
                )
              })}
              <div className='mb-1'>
                {
                  'Tick vào để hiển thị bài viết ở Bảng tin còn không tick thì mặc định sẽ ở Thảo luận'
                }
                <Form.Check
                  className='mb-1'
                  label={`Hiển thị ở bảng tin`}
                  onChange={() => setHighLight(!highLight)}
                />
              </div>
            </>
          ) : null}
          {postStatus === 'APPROVE' ? (
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <Button className='m-1 w-100' variant='danger' onClick={() => managePost('REJECT')}>
                Từ chối bài
              </Button>
              <Button className='m-1 w-100' variant='danger' onClick={() => managePost('DELETE')}>
                Xóa bài
              </Button>
            </div>
          ) : null}
          {postStatus === 'PENDING' ? (
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <Button className='m-1 w-100' variant='info' onClick={() => managePost('APPROVE')}>
                Duyệt bài
              </Button>
              <Button className='m1 w-100' variant='danger' onClick={() => managePost('REJECT')}>
                Từ chối bài
              </Button>
            </div>
          ) : null}
          {postStatus === 'REJECT' ? (
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <Button className='m-1 w-100' variant='info' onClick={() => managePost('APPROVE')}>
                Duyệt bài
              </Button>
              <Button className='m1 w-100' variant='danger' onClick={() => managePost('DELETE')}>
                Ẩn bài
              </Button>
            </div>
          ) : null}

          <PostHistoryComponent postHistory={postHistory} />
        </div>
      </div>
    </div>
  )
}
